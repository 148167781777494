import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import endpoints from "../utils/apiEndpoints";
import apiCallFetch from "../utils/apiHelperFetch";
import { toast } from "react-toastify";
import apiCall from "../utils/apiHelper";

const Header = () => {
  const navigate = useNavigate();
  const empToken =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("EMP_TOKEN")
      : null;
  const seekerToken =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("SEEKER_TOKEN")
      : null;

  const isEmployer = empToken && empToken !== "undefined";
  const isSeeker = seekerToken && seekerToken !== "undefined";

  const [notifications, setNotifications] = useState([]);
  const [comProfile, setComProfile] = useState();
  const sekaerProfile = isSeeker
    ? JSON.parse(localStorage.getItem("SEEKER")).profileImage
    : "/images/user-pic.png";

  const [totalChatCount, setTotalChatCount] = useState(0);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [departments, setDepartments] = useState({});
  const [subDepartments, setSubDepartments] = useState({});

  const handleEmployerLogout = async () => {
    const headers = { Authorization: `${empToken}` };
    try {
      const response = await apiCallFetch(
        endpoints.empLogout,
        "PATCH",
        null,
        headers
      );
      if (response.status) {
        localStorage.clear();
        navigate("/employer/login", { replace: true });
        window.location.reload();
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Employer logout error:", error);
      localStorage.clear();
      navigate("/employer/login", { replace: true });
      window.location.reload();
    }
  };

  const handleSeekerLogout = async () => {
    const headers = { Authorization: `${seekerToken}` };
    try {
      const response = await apiCallFetch(
        endpoints.seekerLogout,
        "PATCH",
        null,
        headers
      );
      if (response.status) {
        localStorage.clear();
        navigate("/login", { replace: true });
        window.location.reload();
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Seeker logout error:", error);
      localStorage.clear();
      navigate("/employer/login", { replace: true });
      window.location.reload();
    }
  };

  const getChatCountList = async () => {
    let header = { Authorization: `${empToken || seekerToken}` };
    try {
      const response = await apiCall(
        `${seekerToken ? endpoints.seekerChat : endpoints.empChat}`,
        "POST",
        { pageNo: 1, searchKey: "" },
        header
      );

      if (response.status) {
        setTotalChatCount(response.totalChatCount);
      }
    } catch (error) {
      console.error("Error fetching chat:", error);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      let headers = { Authorization: `${empToken || seekerToken}` };
      try {
        if (empToken != null) {
          const profileResponse = await apiCallFetch(
            `${endpoints.empProfile}`,
            "GET",
            null,
            headers
          );
          setComProfile(profileResponse.data[0]);
          localStorage.setItem(
            "compLogo",
            profileResponse.data[0].companies.companyImage
          );
          localStorage.setItem(
            "compName",
            profileResponse.data[0].companies.companyName
          );
        }
        const response = await apiCallFetch(
          endpoints.empGetNotification,
          "POST",
          null,
          headers
        );

        if (response.status && response.data) {
          setNotifications(response.data);
        } else {
        }
      } catch (error) {
        // console.error('Error fetching notifications:', error);
      }
    };

    if (isEmployer || isSeeker) {
      fetchNotifications();
    }

    fetchData();
    getChatCountList();
  }, [isEmployer, isSeeker, empToken, seekerToken]);

  const fetchData = async () => {
    try {
      const industryTypesData = await apiCall(
        `${endpoints.getIndustryTypeWeb}`,
        "GET",
        null,
        {}
      );
      setIndustryTypes(industryTypesData?.data || []);

      const allDepartments = {};
      const allSubDepartments = {};
      if (industryTypesData?.data && industryTypesData?.data.length > 0) {
        for (const industry of industryTypesData?.data) {
          const deptTypesData = await apiCall(
            `${endpoints?.webdepartmentType}?industryId=${industry?._id}`,
            "GET",
            null,
            {}
          );
          allDepartments[industry._id] = deptTypesData.data;
          for (const department of deptTypesData.data) {
            try {
              const subdeptTypesData = await apiCall(
                `${endpoints.websubdept}?parentId=${department._id}`,
                "GET",
                null,
                {}
              );
              allSubDepartments[department._id] = subdeptTypesData.data;
            } catch (error) {
              console.error(
                `Error fetching sub-departments for department ID ${department._id}:`,
                error
              );
              allSubDepartments[department._id] = []; // Set an empty array or handle as needed
            }
          }
        }
      }

      setDepartments(allDepartments || {});
      setSubDepartments(allSubDepartments || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLogoClick = (event) => {
    const empToken = localStorage.getItem("EMP_TOKEN");
    const seekerToken = localStorage.getItem("SEEKER_TOKEN");

    if (seekerToken) {
      navigate("/");
      window.location.reload();
    } else if (empToken) {
      navigate("/employer/dashboard");
      window.location.reload();
    }
  };
  const dropdownRef = useRef(null); // Reference for the <li> element
  const menuRef = useRef(null);
  const toggleDropdown = () => {
    if (dropdownRef.current.classList.contains("show")) {
      dropdownRef.current.classList.remove("show");
    } else {
      dropdownRef.current.classList.add("show");
    }
    // Toggle 'show' class on <ul>
    if (menuRef.current.classList.contains("show")) {
      menuRef.current.classList.remove("show");
    } else {
      menuRef.current.classList.add("show");
    }
  };
  const notificationDropdownRef = useRef(null); // Reference for the <li> element
  const notificationMenuRef = useRef(null); // Reference for the <ul> element

  const toggleNotificationDropdown = () => {
    // Toggle 'show' class on <li>
    if (notificationDropdownRef.current.classList.contains("show")) {
      notificationDropdownRef.current.classList.remove("show");
    } else {
      notificationDropdownRef.current.classList.add("show");
    }

    // Toggle 'show' class on <ul>
    if (notificationMenuRef.current.classList.contains("show")) {
      notificationMenuRef.current.classList.remove("show");
    } else {
      notificationMenuRef.current.classList.add("show");
    }
  };

  return (
    <header className="" id="home">
      <nav
        id="navbar-example2"
        className="navbar navbar-expand-lg sticky-top navbar-light bg-white fixedHeader"
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasExample"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand" to="/" onClick={handleLogoClick}>
            <img src="/images/logo.svg" alt="Logo" />
          </Link>

          <div
            className="offcanvas offcanvas-startm-auto offcanvas offcanvas-start offcanvas-menu"
            tabindex="-1"
            id="offcanvasMenu"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header justify-content-center">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.25 12H3.75"
                    stroke="#555"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M10.5 5.25L3.75 12L10.5 18.75"
                    stroke="#555"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>{" "}
              </button>
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                <Link to="/">
                  <img src="/images/logo.svg" alt="Logo" />
                </Link>
              </h5>
            </div>
            <ul className="navbar-nav flex-nowrap d-flex">
              {isEmployer ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/employer/dashboard">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/employer/jobs">
                      My Jobs
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <ul className="navbar-nav flex-nowrap d-flex">
                    <li className="nav-item dropdown dropdown-full">
                      <Link
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        href="find-jobs.php"
                      >
                        Jobs
                      </Link>
                      <div
                        className="dropdown-menu drop-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <div className="row">
                          {industryTypes &&
                            industryTypes.length > 0 &&
                            industryTypes?.map((industry) => (
                              <div
                                key={industry._id}
                                className="col-12 col-sm-12 col-md-6 col-lg-6"
                              >
                                <ul className="list-unstyled dropdown-menu-list pb-0">
                                  <li className="dropdown-header">
                                    {industry.industry}
                                  </li>
                                </ul>
                                <div className="row">
                                  {departments[industry._id]?.map(
                                    (department) => (
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <ul className="list-unstyled dropdown-menu-list pt-0">
                                          <li
                                            key={department._id}
                                            className="dropdown-header d-flex align-items-center"
                                          >
                                            {department.department}
                                          </li>
                                          {subDepartments[department._id]?.map(
                                            (subDept) => (
                                              <li key={subDept._id}>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/joblisting?industry=${industry._id}&department=${department._id}&jobtitleType=${subDept._id}`}
                                                >
                                                  <i className="me-1 fa fa-angle-double-right"></i>
                                                  {subDept.name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </li>
                  </ul>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#companies"
                    >
                      Companies
                    </Link>
                    <ul
                      className="dropdown-menu drop-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {industryTypes &&
                        industryTypes.length > 0 &&
                        industryTypes.map((industry) => (
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/companies?industry=${industry._id}`}
                            >
                              {industry.industry}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/blogs">
                      Blogs
                    </Link>
                  </li>
                </>
              )}
              <li className="nav-item">
                <Link className="nav-link" to="/contactus">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          {isEmployer ? (
            <ul className="navbar-nav ms-auto flex-nowrap d-flex notficationChat-navbar">
              <li className="nav-item">
                <Link className="nav-link position-relative" to="/chat">
                  <img src="/images/chat.svg" alt="Chat" />
                  {totalChatCount === 0 ? (
                    ""
                  ) : (
                    <p className="badge-custom">{totalChatCount}</p>
                  )}
                </Link>
              </li>
              <li
                ref={notificationDropdownRef}
                className="nav-item dropdown notification-nav-item"
                onClick={toggleNotificationDropdown}
              >
                {/* <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" onClick={toggleNotificationDropdown}> */}
                <Link
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="/images/notfication.svg" alt="Notifications" />
                </Link>
                <ul
                  ref={notificationMenuRef}
                  className={`dropdown-menu dropdown-rgt `}
                  aria-labelledby="navbarDropdown"
                >
                  <li className="dropdown-header align-items-center d-flex justify-content-between">
                    Notifications{" "}
                    <Link to="/employer/notifications">Mark all as Read</Link>
                  </li>
                  {notifications.length != 0 ? (
                    notifications.map((notification) => (
                      <li key={notification._id}>
                        <div className="media">
                          <div className="media-left">
                            <div className="notification-icon">
                              {notification.image ? (
                                <img
                                  src={notification.image}
                                  alt="Notification"
                                />
                              ) : (
                                <img src="/images/user-pic.png" alt="Default" />
                              )}
                            </div>
                          </div>
                          <div className="media-body">
                            <h6>{notification.title}</h6>
                            <p>{notification.description}</p>
                          </div>
                          <div className="media-right">
                            <p className="notification-date text-end">
                              <i
                                className={`fa ${
                                  notification.isRead
                                    ? "text-muted"
                                    : "text-blue"
                                } fa-circle`}
                              ></i>
                            </p>
                            <p className="notification-date text-end">
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>
                      <p>No new notifications</p>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          ) : isSeeker ? (
            <ul className="navbar-nav ms-auto flex-nowrap d-flex notficationChat-navbar">
              <li className="nav-item">
                <Link
                  className="nav-link position-relative"
                  to="/chat"
                  state={{ isSeeker: true }}
                >
                  <img src="/images/chat.svg" alt="Chat" />
                  {totalChatCount === 0 ? (
                    ""
                  ) : (
                    <p className="badge-custom">{totalChatCount}</p>
                  )}
                </Link>
              </li>
              <li className="nav-item dropdown notification-nav-item">
                {/* <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" onClick={toggleNotificationDropdown}> */}
                <Link
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="/images/notfication.svg" alt="Notifications" />
                </Link>
                <ul
                  className={`dropdown-menu dropdown-rgt `}
                  aria-labelledby="navbarDropdown"
                >
                  <li className="dropdown-header align-items-center d-flex justify-content-between">
                    Notifications{" "}
                    <Link to="/notifications">Mark all as Read</Link>
                  </li>
                  {notifications.length != 0 ? (
                    notifications.map((notification) => (
                      <li key={notification._id}>
                        <div className="media">
                          <div className="media-left">
                            <div className="notification-icon">
                              {notification.image ? (
                                <img
                                  src={notification.image}
                                  alt="Notification"
                                />
                              ) : (
                                <img src="/images/user-pic.png" alt="Default" />
                              )}
                            </div>
                          </div>
                          <div className="media-body">
                            <h6>{notification.title}</h6>
                            <p>{notification.description}</p>
                          </div>
                          <div className="media-right">
                            <p className="notification-date text-end">
                              <i
                                className={`fa ${
                                  notification.isRead
                                    ? "text-muted"
                                    : "text-blue"
                                } fa-circle`}
                              ></i>
                            </p>
                            <p className="notification-date text-end">
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>
                      <p>No new notifications</p>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          ) : null}

          {(isEmployer || isSeeker) && (
            <ul className="navbar-nav ms-auto flex-nowrap d-flex mobile-navbar-nav">
              {isEmployer && (
                <li className="nav-item">
                  <Link className="nav-link reg-nav" to="/employer/create-job">
                    Post a job
                  </Link>
                </li>
              )}
              <li
                ref={dropdownRef}
                className="nav-item login-drop dropdown"
                onClick={toggleDropdown}
              >
                <Link
                  className="nav-link login-nav dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                >
                  <img
                    src={
                      comProfile?.companies?.companyImage ||
                      sekaerProfile ||
                      "/images/user-pic.png"
                    }
                    alt="User"
                    className="rounded-circle"
                  />{" "}
                  <span>My Account</span>
                </Link>
                <ul
                  ref={menuRef}
                  className="dropdown-menu dropdown-rgt drop-menu"
                  aria-labelledby="navbarDropdown"
                >
                  {isEmployer ? (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/employer/profile">
                          <img src="/images/user-icon.svg" alt="Profile" />{" "}
                          Company Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/employer/subscriptions"
                        >
                          <img src="/images/king-icon.svg" alt="Membership" />{" "}
                          Membership Plan
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/employer/myplan">
                          <img src="/images/user-check.png" alt="My Plan" /> My
                          Plan
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/employer/rating-and-review"
                        >
                          <img
                            src="/images/thumbs-up.svg"
                            alt="Rating & Review"
                          />{" "}
                          Rating & Review
                        </Link>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleEmployerLogout}
                        >
                          <img src="/images/log-out.svg" alt="Logout" /> Logout
                        </button>
                      </li>
                    </>
                  ) : isSeeker ? (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/profile">
                          <img src="/images/user-icon.svg" alt="Profile" /> My
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/subscriptions">
                          <img
                            src="/images/king-icon.svg"
                            alt="Subscriptions"
                          />{" "}
                          Subscriptions
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/myplan">
                          <img src="/images/user-check.png" alt="My Plan" /> My
                          Plan
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/myfavourite">
                          <img src="/images/heart-icon.svg" alt="Favourite" />{" "}
                          Favourite
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/myjobs">
                          <img src="/images/arrow-up.svg" alt="My Jobs" /> My
                          Jobs
                        </Link>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleSeekerLogout}
                        >
                          <img src="/images/log-out.svg" alt="Logout" /> Logout
                        </button>
                      </li>
                    </>
                  ) : null}
                </ul>
              </li>
            </ul>
          )}
          {!isEmployer && !isSeeker && (
            <ul className="navbar-nav ms-auto flex-nowrap d-flex mobile-navbar-nav">
              <li className="nav-item">
                <Link className="nav-link login-nav" to="/login">
                  Candidate Login
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link reg-nav" to="/employer/login">
                  Employer Login
                </Link>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
