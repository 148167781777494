import jobSeekerBarCode from '../assets/images/barCode_jobseeker.png';
import employerBarCode from '../assets/images/barCode_employer.png';
const keysLabel={
   ADSBasedRedirection :"adsBasedRedirection"
}
const payStoreLinks={
    jobSeeker:{
        google:'https://play.google.com/store/apps/details?id=com.arbeit',
        barCode: jobSeekerBarCode
    },
    employer:{
        google:'https://play.google.com/store/apps/details?id=com.arbeit_employee_app',
        barCode:employerBarCode
    }
}
export const CONSTANT={
    keysLabel,
    payStoreLinks
}

export const getGooglePlayStore=()=>{
    console.log(window.location.href, window.location.href.includes('employer'));
    if(window.location.href.includes('employer')){
        return CONSTANT.payStoreLinks.employer;
    } else {
        return CONSTANT.payStoreLinks.jobSeeker;
    }
}

